import React from 'react';
import PathwayNavbar from '../components/Navbars/PathwayNavbar';
import PathwayHome from '../components/PathwayCounseling/PathwayCounselingHome';
// import MentorHome from './MentorHome';

const PathwayCounselingPage = () => {
  return (
    <>
      <PathwayNavbar />
      <PathwayHome />
    </>
  );
};
export default PathwayCounselingPage;
