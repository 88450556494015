import React from 'react';
import MockInterviewNavbar from '../components/Navbars/MockInterviewNavbar';
import TSkills from '../components/TSkills';

const TSkillsPage = () => {
  return (
    <>
      <MockInterviewNavbar />
      <TSkills />
    </>
  );
};
export default TSkillsPage;
